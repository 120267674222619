var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('filtered-table',{attrs:{"items":_vm.items,"loading":_vm.loading,"columns":_vm.columns_joined},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_vm._v(" Naročilo "+_vm._s(item.id)+" "),_c('div',{staticClass:"text-muted"},[(item.created_at)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Datum naročila'),expression:"'Datum naročila'"}]},[_vm._v(" "+_vm._s(_vm.formatDateTime(item.created_at))+" ")]):_vm._e()])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Sledenje statusa naročila'),expression:"'Sledenje statusa naročila'"}],attrs:{"href":_vm.trackStatusLink(item.id),"target":"_blank"}},[_c('span',{class:_vm.getStatusBadgeClass(item.status),staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(item.get_status_display)+" ")])]),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Deli povezavo za sledenje'),expression:"'Deli povezavo za sledenje'"}],staticClass:"m-l-10",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$modal.show('share-link-modal-order-' + item.id);}}},[_c('i',{staticClass:"fa fa-link"})]),_c('ShareLinkModal',{attrs:{"url":_vm.trackStatusLink(item.id),"name":'share-link-modal-order-' + item.id},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('div',{staticClass:"text-center m-b-10"},[_vm._v(" Za sledenje statusa naročila kopirajte povezavo in jo pošljite naročniku. ")])]},proxy:true}],null,true)})]}},{key:"client",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.client_title)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Naziv naročnika'),expression:"'Naziv naročnika'"}]},[_vm._v(" "+_vm._s(item.client_title)+" ")]):_vm._e()]),(item.client_email)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('eMail naslov naročnika'),expression:"'eMail naslov naročnika'"}],staticClass:"text-muted"},[_c('i',{staticClass:"fa fa-envelope"}),_vm._v(" "+_vm._s(item.client_email)+" ")]):_vm._e()]}},{key:"appraisals_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderitem_set.length)+" ")]}},{key:"due_dates",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.quote_due_date)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Rok za oddajo ponudb'),expression:"'Rok za oddajo ponudb'"}]},[_c('i',{staticClass:"fa fa-inbox"}),_vm._v(" "+_vm._s(item.quote_due_date)+" ")]):_vm._e()]),_c('div',[(item.due_date)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Rok za oddajo cenitve'),expression:"'Rok za oddajo cenitve'"}]},[_c('i',{staticClass:"fa fa-paper-plane"}),_vm._v(" "+_vm._s(item.due_date)+" ")]):_vm._e()])]}},{key:"offers_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.offers_amount)+" ")]}},{key:"appraiser",fn:function(ref){
var item = ref.item;
return [(item.appraiser)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Naziv cenilca'),expression:"'Naziv cenilca'"}]},[_vm._v(" "+_vm._s(item.appraiser.first_name)+" "+_vm._s(item.appraiser.last_name)+" ")]):(item.appraiser_team)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Naziv cenilca'),expression:"'Naziv cenilca'"}]},[_vm._v(" "+_vm._s(item.appraiser_team.company_name ? item.appraiser_team.company_name : item.appraiser_team.name)+" ")]):_c('span')]}},_vm._l((_vm.$scopedSlots),function(inx,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }